@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-mono-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono'), local('RobotoMono-Regular'),
       url('./fonts/roboto-mono-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-mono-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-mono-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-mono-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-mono-v7-latin-regular.svg#RobotoMono') format('svg'); /* Legacy iOS */
}

.unselectable,
.unselectable * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  height: 100%;
  width: 100%;
  position: fixed;
  font-family: "Roboto Mono", "Helvetica Neue";
  overflow: hidden;
}

#root {
  height: 100%;
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  > svg {
    flex-grow: 1;
    @media screen and (orientation: portrait) {
      padding: 15px;
    }
  }
  > footer,
  .buttons {
    flex-grow: 0;
    flex-shrink: 0;
  }

  > footer,
  > .buttons {
    margin: 5px;
  }
}

.buttons {
  display: flex;
  button {
    font-size: 1.5em;
    font-weight: bold;
    height: 60px;
    flex-grow: 1;
    margin: 5px 10px;
    max-width: 100%;
    padding: 10px 0;
    background: #fff;
    border: 1px solid #999;
    box-shadow: #aaa 3px 3px 3px;
  }

  @media screen and (orientation: portrait) {
    flex-wrap: wrap;
    button {
      width: 40%;
    }
  }

  @media screen and (orientation: landscape) and (max-height: 400px) {
    button {
      height: 20px;
      padding: 0;
      font-size: 1em;
    }
  }
}

@media screen and (orientation: landscape) and (max-height: 400px) {
  .app {
    display: grid;
    justify-items: stretch;
    grid-template-columns: 75% auto;
    grid-template-rows: 1fr auto;
    height: 100%;
    width: 100%;

    > svg {
      grid-column: 1;
      height: 100%;
      grid-row: 1 / 3;
    }

    > .buttons, > footer {
      align-self: flex-start;
      grid-column-start: 2;  
    }

    .buttons {
      flex-direction: column;
    }
  }
}


footer {
  font-size: 0.7em;
  word-wrap: normal;
}

#log {
  display: none;
  position: absolute;
  top: 5px;
  bottom: 5px;
  overflow: hidden;
  padding-left: 5px;
  text-align: left;
}

.popup--outer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  .popup--inner {
    position: absolute;
    left: 20vw;
    right: 20vw;
    top: 20vh;
    padding: 50px;
    background: white;
    border: 1px solid #999;
    box-shadow: #aaa 3px 3px 3px;

    button {
      font-size: 1.5em;
    }
  }
}
